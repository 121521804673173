.overspendControlReduceWrapper {
  margin-top: 4px;
}

.overspendControlReduceInput {
  padding: 0;
  border: none;
  width: 64px;

  input {
    height: auto;
    font-size: 14px;
    padding: 8px 20px 8px 5px;
    line-height: normal;
  }
}

.overspendControlReduceInputAddon {
  position: absolute;
  top: -3px;
  right: -10px;
  font-size: 14px;
  line-height: 14px;
}

.budgetPacingPeriod {
  max-width: 180px;
}


