// Any variables which related to design

$spacing: 8px;
$dimensions-spacing: 5%;

$font-size-sm: 14px;
$font-size-main: 16px;
$font-size-button: 18px;
$font-size-lg: 28px;

// colors
$green: #3DB14A;
$green-gradient: linear-gradient(86.49deg, #73C94B 5.78%, #15B568 94.22%);
$black: #353333;
$white: #fff;
$light-grey: #FDFDFD;
$grey: #E8E8E8;
$dark-grey: #878787;
$red: #F77D7D;
$orange: #FFA500;

// datepicker
$datepicker__background-color: $white;
$datepicker__border-color: $grey;
$datepicker__highlighted-color: $green;
$datepicker__muted-color: $grey;
$datepicker__selected-color: $green;
$datepicker__text-color: $black;
$datepicker__header-color: $black;
$datepicker__navigation-disabled-color: darken($datepicker__muted-color, 5%);

$datepicker__border-radius: 6px;
$datepicker__day-margin: 0.166rem;
$datepicker__font-size: $font-size-main;
$datepicker__font-family: $font-size-main;
$datepicker__item-size: 1.66rem;
$datepicker__margin: 0.5rem;
$datepicker__navigation-size: 0.35rem;
$datepicker__triangle-size: 6px;

$colors: (
  'green': $green,
  'green-gradient': $green-gradient,
  'black': $black,
  'white': $white,
  'light-grey': $light-grey,
  'grey': $grey,
  'dark-grey': $dark-grey,
  'red': $red,
  'orange': $orange,
);

$header-height: 72px;

$sidebar-width: 272px;

// media
$laptop: 1444px;
$hiddenMenu: 1199px;
$tablet-down: 991px;
$mobile-up: 767px;
$mobile-down: 600px;
