@import "~styles/variables";

.editIcon {
  cursor: pointer;
}

.editMenuContainer {
  position: absolute;
  transform: translate(-100%, -50%);
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 50px rgba(53, 51, 51, 0.05);
  z-index: 4;
}

.editClient {
  border-top: 1px solid $grey;
  padding-top: 12px;
  padding-bottom: 12px;
}

.deleteTeamMember {
  border-top: 1px solid $grey;
  padding-top: 12px;
  padding-bottom: 12px;
}

.imageCell {
  max-width: 100%;
}
