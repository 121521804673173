@import "~styles/variables";

.ReactTable {
  display: block;
  box-shadow: 0 0 50px rgba(53, 51, 51, 0.05);
  border: none;
  border-radius: 8px;

  .rt-table {
    overflow-y: visible;
    overflow-x: auto;
  }

  &.hidden-overflow {
    overflow-y: auto;
      .rt-table {
      overflow-y: auto;
      }
  }

  &.hidden-filters {
    .rt-thead.-header {
      padding: 16px;
    }
  }

  &.budgetTable {
    // .rt-table {
      //   height: 250px;
    // }
    .rt-tbody {
      .rt-tr {
        min-height: 40px;
        padding: 8px 16px;
      }
    }
  }

  &.editTeamMemberTable {
    .rt-table {
      max-height: 216px;
      overflow-y: auto;
    }
  }

  .rt-thead {
    background-color: $white;
    .rt-th {
      overflow: initial;
      border: none;
      padding: 0;
      padding-right: 24px;

      font-weight: 500;
      font-size: $font-size-sm;
      color: $dark-grey;

      &:last-child {
        margin-right: 0;
      }

      &:not(.-cursor-pointer) {
        &:before,
        &:after {
          display: none;
        }
      }
    }

    &.-header {
      box-shadow: none;
      padding: 16px 16px 0;

      .rt-th {
        display: flex;
        justify-content: space-between;

        &:before,
        &:after {
          content: '';
          position: absolute;
          right: 26px;
          width: 0;
          height: 0;
          border-style: solid;
          border-color: transparent;
        }

        &:before {
          bottom: 2px;
          border-width: 4px 3.5px 0 3.5px;
          border-top-color: $black;
        }

        &:after {
          top: 2px;
          border-width: 0 3.5px 4px 3.5px;
          border-bottom-color: $black;
        }

        &.-sort-desc {
          box-shadow: none;
          &::before {
            border-top-color: $green;
          }
        }

        &.-sort-asc {
          box-shadow: none;
          &:after {
            border-bottom-color: $green;
          }
        }
      }
    }

    &.-filters {
      background: $white;
      padding: 0 16px 16px;

      .rt-tr {
        background: #fff;
        margin-top: 8px;
      }

      .rt-th {
        border: none;
        background: $white;
        //padding: 0;
      }

      .wrapper-range {
        width: 100%;
        display: flex;

        input {
          &:first-child {
            margin-right: 5px;
          }

          &:last-child {
            margin-left: 5px;
          }
        }
      }

      select.form-control,
      input.form-control {
        width: 100%;
        max-width: 100%;
        font-size: $font-size-sm;
        font-weight: 500;
        height: 32px;
        padding: 7px;
        // line-height: 16px;
        border-radius: 4px;
      }

      select.form-control {
        appearance: none;
        background-image: url("data:image/svg+xml,%3Csvg width='7' height='4' viewBox='0 0 7 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.5 4L0 0H7L3.5 4Z' fill='%23353333'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: bottom 50% right 8px;
      }
    }
  }

  .rt-tbody {
    .rt-tr {
      min-height: 24px + (16px * 2);
      padding: 16px;

      .rt-td {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        font-weight: 500;
        font-size: $font-size-main;
        // line-height: $font-size-main;
        padding: 0 24px 0 0;
        overflow: inherit;
        border-right: none;

        select {
          width: 100%;
          max-width: 100%;
          font-weight: 500;
          font-size: $font-size-sm;
          height: 32px;
          line-height: 32px;
          padding-left: 8px;
          border-radius: 4px;
          border: 1px solid $grey;
          appearance: none;
          background-color: $white;
          min-width: 80px;
          background-image: url("data:image/svg+xml,%3Csvg width='7' height='4' viewBox='0 0 7 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.5 4L0 0H7L3.5 4Z' fill='%23353333'/%3E%3C/svg%3E%0A");
          background-repeat: no-repeat;
          background-position: bottom 50% right 8px;
        }

        &.contentOverflow {
          a {
            max-width: 234px;
            overflow-x: hidden;
          }
        }
      }
    }
  }

  &.-striped {
    .rt-tr {
      &.-odd {
        background: rgba($grey, .3);
      }
    }
  }
}

.btn.table-button {
  font-size: $font-size-button;
  max-width: 160px;
  max-height: 40px;
  line-height: 40px;
  font-weight: 600;

  @media screen and (max-width: $mobile-down) {
    width: 100%;
    margin-top: 8px;
    max-width: none;
  }
}

.rthfc .-filters .rt-th.rthfc-th-fixed-left-last, .rthfc .rt-th.rthfc-th-fixed-left-last, .rthfc .rt-td.rthfc-td-fixed-left-last {
  border-right: none!important;
}
