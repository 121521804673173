@import "~styles/variables";

.container {
    background: #F14D41;
    color: white;
    padding: 15px 20px;
    margin-left: 272px;
    display: flex;
    align-items: center;

    &.billingAdded {
        background: #3db14a;
    }

    @media screen and (max-width: $hiddenMenu) {
        margin: 0;
    }

    .text {
        font-size: 18px;
        flex-grow: 1;
    }

    .link {
        color: white;
        text-decoration: underline;
    }
}